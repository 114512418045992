import HttpFactory from "../factory";

class ClientZoneModule extends HttpFactory {

  async updateClientZoneData(data: APIRequestBody<"/api/client-zone/update-client-zone-data", "post">) {
    return await this.call("/api/client-zone/update-client-zone-data", "post", {}, data);
  }

  async getUserData() {
    return await this.call("/api/user/get-upgrade-user", "get");
  }

  async saveUserData(data: APIRequestBody<"/api/user/update-upgrade-user", "post">) {
    return await this.call("/api/user/update-upgrade-user", "post", {}, data);
  }

  async getFeaturesData() {
    return await this.call("/api/client-zone/get-features-data", "get");
  }

  async getDevicesData() {
    return await this.call("/api/client-zone/get-devices-data", "get");
  }

  async getHomeCreditFeatures() {
    return await this.call("/api/client-zone/get-home-credit-features", "get");
  }

  async getActiveDevices() {
    return await this.call("/api/client-zone/get-active-devices", "get");
  }

  async getPastDevices() {
    return await this.call("/api/client-zone/get-past-devices", "get");
  }

  async getLimitedOffers() {
    return await this.call("/api/client-zone/get-limited-offers", "get");
  }

  async getFeatures() {
    return await this.call("/api/client-zone/get-features", "get");
  }

  async getRecommendedDevices() {
    return await this.call("/api/client-zone/get-recommended-devices", "get");
  }

}

export default ClientZoneModule;
