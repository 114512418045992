import HttpFactory from "../factory";
import type {ColumnFilter} from "@tanstack/vue-table";

class BuyoutModule extends HttpFactory {
  async updateCustomerInfo() {
    return await this.call("/api/buyout/update-customer-info", "post");
  }

  async createBonusNewDevice() {
    return await this.call("/api/buyout/create-bonus-new-device", "post");
  }

  async createPersonalData() {
    return await this.call("/api/buyout/create-personal-data", "post");
  }

  async updateBuyout() {
    return await this.call("/api/buyout/update-buyout", "post");
  }

  async createDeviceImei() {
    return await this.call("/api/buyout/create-device-imei", "post");
  }

  async buyoutDetail(id: string) {
    return await this.call("/api/buyout/{id}", "get", {path: {id}});
  }

  async buyoutDelete(id: string) {
    return await this.call("/api/buyout/{id}/delete", "delete", {path: {id}});
  }

  async multipleBuyoutDelete() {
    return await this.call("/api/buyout/multiple-buyout-delete", "delete");
  }

  async buyoutBonuses(id: string) {
    return await this.call("/api/buyout/{id}/bonuses", "get", {path: {id}});
  }

  async listTypeBonuses() {
    return await this.call("/api/buyout/type-bonus", "get");
  }

  async buyoutNotContainedBonuses(id: string, buyoutDeviceId: string) {
    return await this.call("/api/buyout/{id}/not-contained-bonuses/{buyout_device_id}", "get", {path: {id, buyout_device_id: buyoutDeviceId}});
  }

  async buyoutNotContainedBonusesForPartner(id: string, buyoutDeviceId: string, partnerId: string) {
    return await this.call("/api/buyout/{id}/not-contained-bonuses/{buyout_device_id}/{partner_id}", "get", {path: {id, buyout_device_id: buyoutDeviceId, partner_id: partnerId}});
  }

  async updateBuyoutBonuses(id: string) {
    return await this.call("/api/buyout/{id}/update-bonuses", "post", {path: {id}});
  }

  async listWaitingBuyouts(page: number, pageSize: number, sort: string, filters: ColumnFilter[]) {
    return await this.call("/api/buyout/list/waiting", "get",{
      query: {
        page,
        page_size: pageSize,
        sort,
      },
    },
    { filters: filters });
  }

  async listBuyouts(page: number, pageSize: number, sort: string, filters: ColumnFilter[]) {
    return await this.call("/api/buyout/list", "get",{
      query: {
        page,
        page_size: pageSize,
        sort,
      },
    },
    { filters: filters });
  }

  async listEmails(id: string, page: number, pageSize: number, sort: string, filters: ColumnFilter[]) {
    return await this.call(`/api/buyout/${id}/emails`, "get",{
      query: {
        page,
        page_size: pageSize,
        sort,
      },
    },
    { filters: filters });
  }

  async listBonuses(page: number, pageSize: number, sort: string, filters: ColumnFilter[]) {
    return await this.call("/api/buyout/bonus/list", "get",{
      query: {
        page,
        page_size: pageSize,
        sort,
      },
    },
    { filters: filters });
  }

  async listBonusesNoPage() {
    return await this.call("/api/buyout/bonus/list", "get");
  }

  async listBonusMasters(page: number, pageSize: number, sort: string, filters: ColumnFilter[]) {
    return await this.call("/api/buyout/bonus-masters/list", "get",{
      query: {
        page,
        page_size: pageSize,
        sort,
      },
    },
    { filters: filters });
  }

  async listBonusMastersNoPage() {
    return await this.call("/api/buyout/bonus-masters/list", "get");
  }

  async assignMasterBonus() {
    return await this.call("/api/buyout/bonus-masters/assign", "post");
  }

  async listCampaigns(page: number, pageSize: number, sort: string, filters: ColumnFilter[]) {
    return await this.call("/api/buyout/campaign/list", "get",{
      query: {
        page,
        page_size: pageSize,
        sort,
      },
    },
    { filters: filters });
  }

  async listCampaignsNoPage() {
    return await this.call("/api/buyout/campaign/list", "get");
  }

  async sendToClientUrl() {
    return await this.call("/api/buyout/send-to-client-url", "post");
  }

  async concept(id: string) {
    return await this.call("/api/buyout/buyout-concept/{id}", "get", {path: {id}});
  }

  async getConcept() {
    return await this.call("/api/buyout/buyout-concept/load-concept", "get");
  }

  async listStatuses() {
    return await this.call("/api/buyout/status/list", "get");
  }

  async setReceived() {
    return await this.call("/api/buyout/set-received", "post");
  }

  async listPartners() {
    return await this.call("/api/buyout/partners/list", "get");
  }

  async listBuyoutPartners() {
    return await this.call("/api/buyout/buyout-partners", "get");
  }

  async fetchQrLabelData(id: string) {
    return await this.call("/api/buyout/qr-label-data/{id}", "get", {path: {id}});
  }

  async contract(id: string) {
    return await this.call("/api/buyout/contract/{id}", "get", {path: {id}});
  }

  async sendContract(id: string) {
    return await this.call("/api/buyout/send-contract/{id}", "post", {path: {id}});
  }

  async downloadContract(id: string) {
    return await this.call("/api/buyout/download-contract/{id}", "get", {path: {id}});
  }

  async approval(id: string) {
    return await this.call("/api/buyout/approval/{id}", "post", {path: {id}});
  }

  async reject(id: string) {
    return await this.call("/api/buyout/reject/{id}", "post", {path: {id}});
  }

  async deleteBonuses(ids: string[]) {
    return await this.call("/api/buyout/bonus", "delete", {query: {ids: ids.join(",")}});
  }

  async bonusId(id: string) {
    return await this.call("/api/buyout/bonus/{id}", "get", {path: {id}});
  }

  async bonusMasterId(id: string) {
    return await this.call("/api/buyout/master-bonus/{id}", "get", {path: {id}});
  }

  async campaignId(id: string) {
    return await this.call("/api/buyout/campaign/{id}", "get", {path: {id}});
  }

  async newDevicesPartnersList(buyoutDeviceId: string) {
    return await this.call("/api/buyout/new-devices-partners-list/{buyout_device_id}", "get", {path: {buyout_device_id: buyoutDeviceId}});
  }

  async newDevicesBonusListByPartner(buyoutDeviceId: string, partnerId: string) {
    return await this.call("/api/buyout/new-devices-bonus-list/{buyout_device_id}/{partner_id}", "get", {path: {buyout_device_id: buyoutDeviceId, partner_id: partnerId}});
  }

  async newDevicesOthersManufacturers(buyoutDeviceId: string, partnerId: string, bonusId: string) {
    return await this.call("/api/buyout/new-devices-others-manufacturers/{buyout_device_id}/{partner_id}/{bonus_id}", "get", {path: {buyout_device_id: buyoutDeviceId, partner_id: partnerId, bonus_id: bonusId}});
  }

  async newDevicesOthersBonusListByPartnerByManufacturerByBonus(buyoutDeviceId: string, partnerId: string, manufacturerId: string, bonusId: string) {
    return await this.call("/api/buyout/new-devices-others-bonus-list/{buyout_device_id}/{partner_d}/{manufacturer_id}/{bonus_id}", "get", {path: {buyout_device_id: buyoutDeviceId, partner_d: partnerId, manufacturer_id: manufacturerId, bonus_id: bonusId}});
  }

  async bonusByOldNewByPartner(buyoutDeviceId: string, masterDeviceId: string, partnerId: string) {
    return await this.call("/api/buyout/bonus-by-old-new/{buyout_device_id}/{master_device_id}/{partner_d}", "get", {path: {buyout_device_id: buyoutDeviceId, master_device_id: masterDeviceId, partner_d: partnerId}});
  }

  async buyoutDefects(buyoutDeviceId: string) {
    return await this.call("/api/buyout/defects/{buyout_device_id}", "get", {path: {buyout_device_id: buyoutDeviceId}});
  }

  async selectedBuyoutDefects(buyoutId: string) {
    return await this.call("/api/buyout/{buyout_id}/defects", "get", {path: {buyout_id: buyoutId}});
  }

  async typeBuyoutChecks(buyoutDeviceId: string) {
    return await this.call("/api/buyout/buyout-checks/{buyout_device_id}", "get", {path: {buyout_device_id: buyoutDeviceId}});
  }

  async buyoutChecks(buyoutId: string) {
    return await this.call("/api/buyout/{buyout_id}/checks", "get", {path: {buyout_id: buyoutId}});
  }

  async buyoutAccessories(buyoutId: string) {
    return await this.call("/api/buyout/get-buyout-accessories/{buyout_id}", "get", {path: {buyout_id: buyoutId}});
  }

  async buyoutDetailByNumber(buyoutId: string) {
    return await this.call("/api/buyout/buyout-detail/{buyout_id}", "get", {path: {buyout_id: buyoutId}});
  }

  async enhancedBuyoutDetailByNumber(buyoutId: string) {
    return await this.call("/api/buyout/enhanced-buyout-detail/{buyout_id}", "get", {path: {buyout_id: buyoutId}});
  }

  async buyoutPartnerByBuyoutNumber(buyoutId: string) {
    return await this.call("/api/buyout/buyout-partner/{buyout_id}", "get", {path: {buyout_id: buyoutId}});
  }

  async updateNewDevice(buyoutNumber: string) {
    return await this.call("/api/buyout/update-new-device/{buyout_number}", "post", {path: {buyout_number: buyoutNumber}});
  }

  async searchMasterDevices(buyoutId: string, searchValue: string) {
    return await this.call("/api/buyout/master-devices/{buyout_id}/search", "get", {path: {buyout_id: buyoutId}, query: {q: searchValue}});
  }

  async cancelChangeDevice(buyoutId: string) {
    return await this.call("/api/buyout/{buyout_id}/cancel-device-change", "post", {path: {buyout_id: buyoutId}});
  }

  async changeDevice(buyoutId: string, masterDeviceId: string, colorId: string) {
    return await this.call("/api/buyout/{buyout_id}/device/{master_device_id}/{color_id}", "post", {path: {buyout_id: buyoutId, master_device_id: masterDeviceId, color_id: colorId}});
  }

  async defectsByDevice(buyoutDeviceColorId: string) {
    return await this.call("/api/buyout/defects/{buyout_device_color_id}", "get", {path: {buyout_device_color_id: buyoutDeviceColorId}});
  }

  async accessoriesByMasterDevice(buyoutDeviceDeviceTypeId: string) {
    return await this.call("/api/buyout/available-accessories/{buyout_device_device_type_id}", "get", {path: {buyout_device_device_type_id: buyoutDeviceDeviceTypeId}});
  }

  async setReturned() {
    return await this.call("/api/buyout/set-returned", "post");
  }

  async changeAccepted(buyoutId: string) {
    return await this.call("/api/buyout/change-accepted/{buyout_id}", "post", {path: {buyout_id: buyoutId}});
  }

  async changeReceive(buyoutId: string) {
    return await this.call("/api/buyout/change-receive/{buyout_id}", "post", {path: {buyout_id: buyoutId}});
  }

  async bossExport() {
    return await this.call("/api/buyout/boss-export", "get");
  }

  async loadNewDevice(newDeviceId: string) {
    return await this.call("/api/buyout/load-new-device/{new_device_id}", "get", {path: {new_device_id: newDeviceId}});
  }

  async loadBuyoutBonus(userBuyoutCode: string) {
    return await this.call("/api/buyout/load-buyout-bonus/{user_buyout_code}", "get", {path: {user_buyout_code: userBuyoutCode}});
  }

  async buyoutRevaluationReaction(buyoutId: string) {
    return await this.call("/api/buyout/revaluation-reaction/{buyout_id}", "post", {path: {buyout_id: buyoutId}});
  }

  async buyoutConflictReaction(buyoutId: string) {
    return await this.call("/api/buyout/conflict-reaction/{buyout_id}", "post", {path: {buyout_id: buyoutId}});
  }

  async resendEmails(buyoutId: string, ids: string) {
    return await this.call("/api/buyout/{buyout_id}/resend-emails", "post", {path: {buyout_id: buyoutId}, query: {ids}});
  }

  async buyoutHistorySummaryGrid() {
    return await this.call("/api/buyout/log/list", "get");
  }

  async buyoutHistoryGrid(id: string, page: number, pageSize: number, sort: string, filters: ColumnFilter[]) {
    return await this.call("/api/buyout/{id}/log/list", "get",{
      path: { id },
      query: {
        page,
        page_size: pageSize,
        sort,
      },
    },
    { filters: filters });
  }

  async isNewCustomer(buyoutId: string) {
    return await this.call("/api/buyout/is-new-customer/{buyout_id}", "get", {path: {buyout_id: buyoutId}});
  }

  async paperContractSign(buyoutId: string) {
    return await this.call("/api/buyout/paper-contract-sign/{buyout_id}", "post", {path: {buyout_id: buyoutId}});
  }

  async getCustomerBasicInfo(buyoutId: string) {
    return await this.call("/api/buyout/get-customer-basic-info/{buyout_id}", "get", {path: {buyout_id: buyoutId}});
  }

  async loadCustomersContract(buyoutId: string) {
    return await this.call("/api/buyout/load-customers-contract/{buyout_id}", "get", {path: {buyout_id: buyoutId}});
  }

  async checkAccessCode() {
    return await this.call("/api/buyout/check-access-code", "post");
  }

  async loadCustomerData(buyoutId: string) {
    return await this.call("/api/buyout/load-customer-data/{buyout_id}", "get", {path: {buyout_id: buyoutId}});
  }

  async updateBuyoutPersonalData(buyoutId: string) {
    return await this.call("/api/buyout/update-buyout-personal-data/{buyout_id}", "post", {path: {buyout_id: buyoutId}});
  }

  async signContract(buyoutId: string) {
    return await this.call("/api/buyout/sign-contract/{buyout_id}", "post", {path: {buyout_id: buyoutId}});
  }

  async sendAccessCode(buyoutId: string) {
    return await this.call("/api/buyout/send-access-code/{buyout_id}", "post", {path: {buyout_id: buyoutId}});
  }

  async sendSignConfirmationCode(buyoutId: string) {
    return await this.call("/api/buyout/send-sign-confirmation-code/{buyout_id}", "post", {path: {buyout_id: buyoutId}});
  }

  async userBuyoutAddress(userBuyoutCode: string) {
    return await this.call("/api/buyout/user_buyout_address/{user_buyout_code}", "get", {path: {user_buyout_code: userBuyoutCode}});
  }

  async manufacturesWithMasterBonuses() {
    return await this.call("/api/buyout/manufactures-with-master-bonuses", "get");
  }

  async manufacturesWithMasterBonusesForPartner(partnerId: string) {
    return await this.call("/api/buyout/manufactures-with-master-bonuses/{partner_id}", "get", {path: {partner_id: partnerId}});
  }

  async partnersWithBonuses() {
    return await this.call("/api/buyout/partners-with-bonuses", "get");
  }

  async listLanguages() {
    return await this.call("/api/buyout/languages", "get");
  }

  async buyoutNote(id: string) {
    return await this.call("/api/buyout/buyout-note/{id}", "get", {path: {id}});
  }
}

export default BuyoutModule;
