export default function useTTL () {
  const TTL_SEC = 1000;
  const TTL_MIN = TTL_SEC * 60;
  const TTL_HOUR = TTL_MIN * 60;
  const TTL_DAY = TTL_HOUR * 24;
  const TTL_WEEK = TTL_DAY * 7;
  const TTL_MONTH = TTL_DAY * 30;
  const TTL_YEAR = TTL_DAY * 365;

  return {
    TTL_SEC,
    TTL_MIN,
    TTL_HOUR,
    TTL_DAY,
    TTL_WEEK,
    TTL_MONTH,
    TTL_YEAR
  };
}
