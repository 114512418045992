import HttpFactory from "../factory";

class CommonModule extends HttpFactory {

  async printers() {
    return await this.call("/api/ct_common/printers/", "get");
  }

  async sendEmail() {
    return await this.call("/api/ct_common/send-email/", "post");
  }

  async bankConfirmationSend() {
    return await this.call("/api/ct_common/bank-confirmation-send/", "post");
  }

  async referralBuyoutOfferSend() {
    return await this.call("/api/ct_common/referral-buyout-offer-send/", "post");
  }

  async listNotification() {
    return await this.call("/api/ct_common/notifications/", "get");
  }

  async markNotificationAsRead() {
    return await this.call("/api/ct_common/notifications/mark_as_read/", "post");
  }

  async markAllNotificationAsRead() {
    return await this.call("/api/ct_common/notifications/mark_all_as_read/", "post");
  }

  async csvExample(file: string) {
    return await this.call("/api/ct_common/csv-example/{file}", "get", {path: {file}});
  }

  async showIban() {
    return await this.call("/api/ct_common/show-iban/", "get");
  }

  async activeNotification() {
    return await this.call("/api/ct_common/type_notifications/active", "get");
  }

  async sendNotification() {
    return await this.call("/api/ct_common/send_notification/", "post");
  }

  async listRegions() {
    return await this.call("/api/ct_common/regions-list", "get");
  }

  async contactUsForm(formData: APIRequestBody<"/api/ct_common/contact-us-form", "post">) {
    return await this.call("/api/ct_common/contact-us-form", "post", {}, formData);
  }

  async chatUsForm(formData: APIRequestBody<"/api/ct_common/chat-us", "post">) {
    return await this.call("/api/ct_common/chat-us", "post", {}, formData);
  }

}

export default CommonModule;
