// import { h } from "vue";
import { h, defineAsyncComponent } from "vue";
import type { IconSet, IconProps } from "vuetify";

// export const iconSet: IconSet = {
//   component: async (props: IconProps) => h("img", { src: await import(`./assets/svg/ico/${props.icon}.svg`) })
// };

// export const iconSet: IconSet = defineAsyncComponent(() =)
export const iconSet: IconSet = {
  component: (props: IconProps) => h(
    props.tag,
    {},
    [h(defineAsyncComponent(() => import(`./assets/svg/${props.icon}.svg`).catch(() => {
      // eslint-disable-next-line no-console
      console.warn(`Failed to load icon ${props.icon}`);
      return { render: () => "" };
    })), { class: `icon icon--${props.icon}` })]
  )
};
