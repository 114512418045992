export default defineNuxtRouteMiddleware(async (to) => {
  const metaAuth = to.meta?.auth ?? true;
  const { $localePath: localePath, $pinia } = useNuxtApp();
  const authStore = useAuthStore($pinia);

  if (authStore.accessToken !== null && !authStore.isLoggedIn) {
    if (authStore.isAccessTokenExpired()) {
      await authStore.refreshAccessToken();
    }
    await authStore.getSession();
  }

  if (metaAuth === false) {
    return;
  }

  if (authStore.isLoggedIn) {
    return;
  }

  return navigateTo(localePath({ name: "index" }));
});
