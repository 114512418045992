import HttpFactory from "../factory";
import type {ColumnFilter} from "@tanstack/vue-table";

class PaymentModule extends HttpFactory {

  async listFilterData() {
    return await this.call("/api/ct_payment/list-filter-data", "get");
  }

  async listPayments(page: number, pageSize: number, sort: string, filters: ColumnFilter[]) {
    return await this.call("/api/ct_payment/list", "post", {
      query: {
        page,
        page_size: pageSize,
        sort,
      },
    },
    { filters: filters });
  }

  async listExports(page: number, pageSize: number, sort: string, filters: ColumnFilter[]) {
    return await this.call("/api/ct_payment/list-exports", "post", {
      query: {
        page,
        page_size: pageSize,
        sort,
      },
    },
    { filters: filters });
  }

  async listPaymentsForBuyout(page: number, pageSize: number, sort: string, filters: ColumnFilter[], id: string) {
    return await this.call("/api/ct_payment/list/{id}", "post", {
      path: { id },
      query: {
        page,
        page_size: pageSize,
        sort,
      },
    },
    { filters: filters });
  }

  async requestRewardPayment() {
    return await this.call("/api/ct_payment/request-reward-payment", "post");
  }

  async bankAccountVerificationData() {
    return await this.call("/api/ct_payment/bank-account-verification-data", "get");
  }

  async generateOrderAndChangeStatus() {
    return await this.call("/api/ct_payment/generate-order-and-change-status", "post");
  }

  async downloadPaymentOrder(id: string) {
    return await this.call("/api/ct_payment/download-payment-export/{id}", "get", {path: {id}});
  }

  async denyPayment() {
    return await this.call("/api/ct_payment/deny-payment", "post");
  }

  async authorizePayment() {
    return await this.call("/api/ct_payment/authorize-payment", "post");
  }

  async setForPayment() {
    return await this.call("/api/ct_payment/set-for-payment", "post");
  }

  async hasUserPayPermission() {
    return await this.call("/api/ct_payment/has-user-pay-permission", "get");
  }

  async validateForPaymentCsv() {
    return await this.call("/api/ct_payment/validate-for-payment-csv", "post");
  }

  async importForPaymentCsv() {
    return await this.call("/api/ct_payment/import-for-payment-csv", "post");
  }

  async setStatusBasedOnBoss() {
    return await this.call("/api/ct_payment/set_status_based_on_boss", "post");
  }

  async markPaymentAsPaid() {
    return await this.call("/api/ct_payment/mark-payment-as-paid", "post");
  }
}

export default PaymentModule;
