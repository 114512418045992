import HttpFactory from "../factory";

class StoreModule extends HttpFactory {

  async availableMpStores() {
    return await this.call("/api/up_stores/get-available-mp-stores", "get");
  }

  async availableCashDesk() {
    return await this.call("/api/up_stores/get-available-cash-desk", "get");
  }
}

export default StoreModule;
