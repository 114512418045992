import HttpFactory from "../factory";
import type {ColumnFilter} from "@tanstack/vue-table";

class ChatModule extends HttpFactory {

  async userChatRooms() {
    return await this.call("/api/ct_chat/chat-rooms", "get");
  }

  async chatRoomsWithoutMpStaff() {
    return await this.call("/api/ct_chat/chat-rooms-/without-mp-staff", "get");
  }
  async chatRoomCreateFromBuyout(data: APIRequestBody<"/api/ct_chat/chat-rooms/create-from-buyout", "post">) {
    return await this.call("/api/ct_chat/chat-rooms/create-from-business-issue", "post", {}, { ...data });
  }

  async chatRoomCreateFromBusinessIssue(businessCaseIssueId: APIRequestBody<"/api/ct_chat/chat-rooms/create-from-business-issue", "post">) {
    return await this.call("/api/ct_chat/chat-rooms/create-from-business-issue", "post", {}, { business_case_issue: businessCaseIssueId });
  }

  async chatRoomJoin(chatRoomId: string) {
    return await this.call("/api/ct_chat/chat-room/{chat_room_id}/join", "post", {path: {chat_room_id: chatRoomId}});
  }

  async chatRoomDetail(chatRoomId: string) {
    return await this.call("/api/ct_chat/chat-room/{chat_room_id}", "get", {path: {chat_room_id: chatRoomId}});
  }

  async chatRoomWrite(chatRoomId: string, data: object) {
    return await this.call("/api/ct_chat/chat-room/{chat_room_id}/write", "post", {path: {chat_room_id: chatRoomId}}, data);
  }

  async chatRoomUnreadMessages(chatRoomId: string) {
    return await this.call("/api/ct_chat/chat-room/{chat_room_id}/unread-messages", "get", {path: {chat_room_id: chatRoomId}});
  }

  async chatRoomUnreadMessagesAnonymous(chatRoomId: string) {
    return await this.call("/api/ct_chat/chat-room/{chat_room_id}/unread-messages/anonymous", "get", {path: {chat_room_id: chatRoomId}});
  }

  async chatRoomJoinAndRetrieve(chatRoomId: string) {
    return await this.call("/api/ct_chat/chat-room/{chat_room_id}/join-and-retrieve", "post", {path: {chat_room_id: chatRoomId}});
  }

  async chatRoomMoreMessages(chatRoomId: string, oldestMessageId: string) {
    return await this.call("/api/ct_chat/chat-room/{chat_room_id}/load-more-messages/{oldest_message_id}", "get", {path: {chat_room_id: chatRoomId, oldest_message_id: oldestMessageId}});
  }

  async initializeChatRoom(registrationNumber: string) {
    return await this.call("/api/ct_chat/initialize-chat-room/{registration_number}", "post", {path: {registration_number: registrationNumber}});
  }

  async chatRoomMedia(chatRoomId: string) {
    return await this.call("/api/ct_chat/chat-room/{chat_room_id}/media", "get", {path: {chat_room_id: chatRoomId}});
  }

  async updateLastSeen(chatRoomId: string) {
    return await this.call("/api/ct_chat/chat-room/update-last-seen/{chat_room_id}", "post", {path: {chat_room_id: chatRoomId}});
  }

  async chatRoomsAdminGrid(pageNumber: number, pageSize: number, sort: string, filters: ColumnFilter[]) {
    return await this.call("/api/ct_chat/chat-rooms/admin-grid", "post", {
      query: {
        pageNumber,
        pageSize,
        sort
      }
    },
    {filters: filters});
  }

  async chatRoomSetStatus(chatRoomId: string, data: object) {
    return await this.call("/api/ct_chat/chat-room/{chat_room_id}/set-status", "post", {path: {chat_room_id: chatRoomId}}, data);
  }

  async chatRoomTradeinDetail(chatRoomId: string) {
    return await this.call("/api/ct_chat/chat-room/{chat_room_id}/tradein-detail", "get", {path: {chat_room_id: chatRoomId}});
  }
}

export default ChatModule;
