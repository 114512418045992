import { isEmpty } from "lodash-es";
import type { $Fetch, FetchError } from "ofetch";
import type { AsyncData } from "nuxt/app";
// import type { APIPath, APIPathMethod, APIRequestBody, APIRequestParams, APIResponseType } from "../types/common";

class HttpFactory {
  private $fetch: $Fetch;

  constructor(fetcher: $Fetch) {
    this.$fetch = fetcher;
  }

  prepareURLParams(url: string, params: object): string {
    const paramRegex = /{([a-zA-Z_]+)}/g;
    const urlParams = (url.match(paramRegex) || []).map(param => param.replace("{", "").replace("}", ""));
    let newURL = url;
    urlParams.forEach((param) => {
      if (params?.[param as keyof typeof params] === undefined || params?.[param as keyof typeof params] === null) {
        newURL = newURL.replace(`{${param}}/`, "");
      } else {
        newURL = newURL.replace(`{${param}}`, params[param as keyof typeof params]);
      }
    });

    return newURL;
  }

  getCacheKey(url: APIPath, method: string, params?: Record<string, unknown>, body?: Record<string, unknown>) {
    return `${String(url)}_${String(method)}_${JSON.stringify(params)}_${JSON.stringify(body)}`;
  }

  isEmptyBody(body: unknown): boolean {
    if (body instanceof FormData) {
      return ![...body.keys()].length;
    } else {
      return isEmpty(body);
    }
  }

  async call<P extends APIPath, M extends APIPathMethod<P>>(
    url: P,
    method: M,
    params: APIRequestParams<P, M> extends undefined ? Record<string, unknown> : APIRequestParams<P, M> = {},
    body: APIRequestBody<P, M> extends undefined ? Record<string, unknown> : APIRequestBody<P, M> = {},
    options: object = {}
  ): Promise<AsyncData<APIResponseType<P, M>, FetchError | null>> {
    return useFetch<APIResponseType<P, M>, FetchError | null>(this.prepareURLParams(String(url), params?.path ?? {}), {
      ...options,
      credentials: "include",
      body: this.isEmptyBody(body) ? undefined : body,
      query: params?.query as Record<string, never>,
      key: this.getCacheKey(url, method as string, params, body),
      $fetch: this.$fetch as typeof globalThis.$fetch,
      method: method,
    });
  }
}

export default HttpFactory;
