import HttpFactory from "../factory";
import type { ColumnFilter } from "@tanstack/vue-table";

class BlackListModule extends HttpFactory {

  async listDevices(page: number, pageSize: number, sort: string, filters: ColumnFilter[]) {
    return await this.call("/api/ct_blacklist/list/devices", "post", {
      query: {
        page,
        page_size: pageSize,
        sort,
      },
    },
    { filters: filters });
  }

  async listUsers(page: number, pageSize: number, sort: string, filters: ColumnFilter[]) {
    return await this.call("/api/ct_blacklist/list/users", "post", {
      query: {
        page,
        page_size: pageSize,
        sort,
      },
    },
    { filters: filters });
  }

  async deleteDevices(ids: string[]) {
    return await this.call("/api/ct_blacklist/delete/devices", "post", {}, { ids });
  }

  async deleteUsers(ids: string[]) {
    return await this.call("/api/ct_blacklist/delete/users", "post", {}, { ids });
  }

  async device() {
    return await this.call("/api/ct_blacklist/device", "get");
  }

  async user() {
    return await this.call("/api/ct_blacklist/user", "get");
  }

  async importDevices(data: object) {
    return await this.call("/api/ct_blacklist/import/devices", "post", {}, data);
  }

  async importUsers(data: object) {
    return await this.call("/api/ct_blacklist/import/users", "post", {}, data);
  }
}

export default BlackListModule;
