import HttpFactory from "../factory";

class AuthModule extends HttpFactory {
  async login() {
    return await this.call("/api/user/login", "post");
  }

  async changeLanguage(language: string) {
    return await this.call("/api/user/change/language/{language}", "post", {path: {language}});
  }

  async tradeinLogin() {
    return await this.call("/api/user/tradein-login", "post");
  }

  async upgradeLogin(email: string, path: string) {
    return await this.call("/api/user/upgrade-login", "post", {}, {email: email, path: path });
  }

  async tokenLogin(token: string) {
    return await this.call("/api/user/token-login", "post", {}, { token: token });
  }

  async verifyOtp() {
    return await this.call("/api/user/verify-otp", "post");
  }

  async refreshToken(refreshToken: string) {
    return await this.call("/api/user/refresh-token", "post", {}, { refresh: refreshToken });
  }

  async session() {
    return await this.call("/api/user/session", "get");
  }

  async logout(refreshToken: string) {
    return await this.call("/api/user/logout", "post", {}, { refresh_token: refreshToken });
  }

  async registerAmbassador() {
    return await this.call("/api/user/register/ambassador", "post");
  }

  async registerPartner() {
    return await this.call("/api/user/register/partner", "post");
  }

  async forgottenPassword() {
    return await this.call("/api/user/forgotten", "post");
  }

  async recovery(uid: string, token: string) {
    return await this.call("/api/user/recovery-password/{uid}/{token}", "post", {path: {uid, token}});
  }

  async userInfo() {
    return await this.call("/api/user/get-user-info", "get");
  }

  async userOtp() {
    return await this.call("/api/user/get-otp", "post");
  }

  async ares() {
    return await this.call("/api/user/ares", "get");
  }

  async contactUs() {
    return await this.call("/api/user/contact-us", "post");
  }

  async newPhoneNumber() {
    return await this.call("/api/user/new-phone-number", "post");
  }

  async allCategories() {
    return await this.call("/api/user/categories", "get");
  }

  async multimediaByCategory(categoryId: string, page: number) {
    return await this.call("/api/user/categories/{category_id}/media/", "get", {path: {category_id: categoryId}, query: {page}});
  }

  async markAsAccessed() {
    return await this.call("/api/user/accessed-media", "post");
  }

  async inviteAmbassador() {
    return await this.call("/api/user/invite-ambassador", "post");
  }

  async loadAmbassadors() {
    return await this.call("/api/user/load-ambassadors", "get");
  }

  async partnerAmbassador() {
    return await this.call("/api/user/crud-partner-ambassador", "post");
  }

  async loadInvitedAmbassadors() {
    return await this.call("/api/user/load-invited-ambassadors", "get");
  }

  async crudContactPersons() {
    return await this.call("/api/user/crud-contact-person", "post");
  }

  async loadContactPersons() {
    return await this.call("/api/user/load-contact-persons", "get");
  }

  async companyData() {
    return await this.call("/api/user/company-data", "get");
  }

  async registerImportedAmbassadors() {
    return await this.call("/api/user/import-ambassadors", "post");
  }

  async verifyImportedAmbassadors() {
    return await this.call("/api/user/verify-imported-ambassadors", "post");
  }

  async loadAllBonuses() {
    return await this.call("/api/user/load-bonuses", "get");
  }

  async loadBonuses(id: string) {
    return await this.call("/api/user/load-bonuses/{id}", "get", {path: {id}});
  }

  async activateAccount() {
    return await this.call("/api/user/activate-account", "post");
  }

  async loadBonusesFilter() {
    return await this.call("/api/user/load-bonuses-filter", "get");
  }

  async verifyPasswords() {
    return await this.call("/api/user/verify-passwords", "post");
  }

  async partnerApiKeys() {
    return await this.call("/api/user/partner-api/keys", "get");
  }

  async partnerApiKey() {
    return await this.call("/api/user/partner-api/key", "get");
  }

  async getAmbassadorStores(partnerId: string) {
    return await this.call("/api/user/get-ambassador-stores/{partner_id}", "get", {path: {partner_id: partnerId}});
  }
}

export default AuthModule;
