import HttpFactory from "../factory";

class CartModule extends HttpFactory {

  async getCart() {
    return await this.call("/api/up_cart/cart", "get");
  }

  async createCart(data: APIRequestBody<"/api/up_cart/cart", "post">) {
    return await this.call("/api/up_cart/cart", "post", {}, data);
  }

  async createOrder(data: APIRequestBody<"/api/up_cart/order", "post">) {
    return await this.call("/api/up_cart/create-order", "post", {}, {...data}, {"watch": false});
  }
}

export default CartModule;
