<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<script setup lang="ts">
const { t } = useI18n();
const runtimeConfig = useRuntimeConfig();

useHead({
  meta: [
    { name: "description", content: t("static.meta.description", "Prodejte výhodně své použité zařízení a dejte mu další šanci. Garantujeme cenu z nejlepších na trhu! Pomáhejte druhým prodat svá použitá zařízení a my vás za to finančně odměníme.") },
    { property: "og:description", content: t("static.meta.description") }
  ],
  link: [
    { rel: "apple-touch-icon", sizes: "180x180", href: `${runtimeConfig.public.baseUrl}/apple-touch-icon.png?v=1.01` },
    { rel: "icon", type: "image/png", sizes: "32x32", href: `${runtimeConfig.public.baseUrl}/favicon-32x32.png?v=1.01` },
    { rel: "icon", type: "image/png", sizes: "16x16", href: `${runtimeConfig.public.baseUrl}/favicon-16x16.png?v=1.01` },
    { rel: "mask-icon", href: `${runtimeConfig.public.baseUrl}/safari-pinned-tab.svg?v=1.01`, color: "#f10375" },
    { rel: "shortcut icon", href: `${runtimeConfig.public.baseUrl}/favicon.ico?v=1.01` }
  ]
});
</script>
