import HttpFactory from "../factory";

class CatalogueModule extends HttpFactory {

  async getAvailableTypeDevices() {
    return await this.call("/api/up_catalogue/device-types", "get");
  }

  async getAvailableManufacturers(seoName: string) {
    return await this.call("/api/up_catalogue/manufacturers-by-category/{device_type_identifier}", "get", {path: {device_type_identifier: seoName}});
  }

  async getMasterDevices(query: object) {
    return await this.call("/api/up_catalogue/list/master-devices", "get", {query});
  }
  async getFilters(query: object) {
    return await this.call("/api/up_catalogue/filters", "get", {query});
  }
}

export default CatalogueModule;
