import HttpFactory from "../factory";

class ExportModule extends HttpFactory {
  async exportData(exportName: string) {
    return await this.call("/api/ct_export/export/{export_name}", "get", { params: { export_name: exportName } });
  }

  async exportInfo() {
    return await this.call("/api/ct_export/export-info", "get");
  }
}

export default ExportModule;
