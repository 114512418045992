import HttpFactory from "../factory";

class FaqModule extends HttpFactory {
  async getFaq(identifier: string) {
    return await this.call("/api/faq/faq", "get", { query: { identifier } });
  }

}

export default FaqModule;
