import mitt from "mitt";
import type { Emitter } from "mitt";

export const eventTypes = {
  ALL_VIDEOS_PAUSE: "allVideosPause",
  VIDEO_WATCHED: "videoWatched",
  CONTACT_PERSON_DELETED: "contactPersonDeleted",
  REFRESH_PARTNER_AMBASSADORS_GRID: "partnerEditedAmbassadors"
} as const;

export type Events = {
  [eventTypes.ALL_VIDEOS_PAUSE]: void,
  [eventTypes.VIDEO_WATCHED]: { url: string, isWatched: boolean },
  [eventTypes.CONTACT_PERSON_DELETED]: void,
  [eventTypes.REFRESH_PARTNER_AMBASSADORS_GRID]: void
};

export default defineNuxtPlugin(() => {
  const emitter: Emitter<Events> = mitt<Events>();

  return {
    provide: {
      bus: {
        ...emitter,
        EVENT_TYPES: eventTypes
      }
    }
  };
});
