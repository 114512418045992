import HttpFactory from "../factory";
import type {ColumnFilter} from "@tanstack/vue-table";

class InstallmentsModule extends HttpFactory {

  async installmentsMasterProductsGrid(page: number, pageSize: number, sort: string, filters: ColumnFilter[]) {
    return await this.call("/api/up_installments/installments-master-products-grid", "post",
      {
        query: {
          page,
          page_size: pageSize,
          sort,
        },
      },
      // TODO - filters for this grid are currently not working, probably because it cant filter fields from other tables
      // look at cashtec commits - 0ab391c7d6f41f899ccbc4eb8a897d1c245aeb07+ and a86da3da7fb1e188614bb0ed05d1ee682d4bc737 for a fix
      { filters: filters },
    );
  }

  async installmentsVariantsGrid(page: number, pageSize: number, sort: string, filters: ColumnFilter[]) {
    return await this.call("/api/up_installments/installments-variants-grid", "post",
      {
        query: {
          page,
          page_size: pageSize,
          sort,
        },
      },
      { filters: filters },
    );
  }

  async installmentsVariantsList() {
    return await this.call("/api/up_installments/installments-variants-list", "get");
  }

  async deviceCalculatedVariants(page: number, pageSize: number, sort: string, filters: ColumnFilter[], masterDeviceId: string) {
    return await this.call("/api/up_installments/device-calculated-variants/{master_device_id}", "post", {
      path: { master_device_id: masterDeviceId },
      query: {
        page,
        page_size: pageSize,
        sort,
      },
    },
    { filters: filters },
    );
  }

  async addInstallmentsMasterProduct(data: APIRequestBody<"/api/up_installments/installments-master-product", "post">) {
    return await this.call("/api/up_installments/installments-master-product", "post", {}, data);
  }

  async deleteInstallmentsMasterProduct(ids: string) {
    return await this.call("/api/up_installments/installments-master-product", "delete", {query: {ids}});
  }

  async saveInstallmentsMasterProduct(data: APIRequestBody<"api/up_installments/save-installments-master-product", "post">) {
    return await this.call("/api/up_installments/save-installments-master-product", "post", {}, data);
  }

  async deleteInstallmentsVariant(ids: string) {
    return await this.call("/api/up_installments/installments-variant", "delete", {query: {ids}});
  }

  async saveInstallmentsVariants(data: APIRequestBody<"/api/up_installments/save-installments-variants", "post">) {
    return await this.call("/api/up_installments/save-installments-variants", "post", {}, data);
  }
}

export default InstallmentsModule;
